<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 提示信息 -->
            <alert-info :info="infodata"></alert-info>
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">四有档案信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="数据密级" prop="sjmj">
                    <el-select
                        v-model="formdata.saveJson.sjmj"
                        placeholder="请选择数据密级"
                        :disabled="!isEdit"
                        @change="changeSJMJ"
                    >
                        <el-option label="公开" value="公开"></el-option>
                        <el-option label="不公开" value="不公开"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    v-show="formdata.saveJson.sjmj === '公开'"
                    label="发布范围"
                    prop="fbfw"
                >
                    <el-select
                        v-model="formdata.saveJson.fbfw"
                        placeholder="请选择发布范围"
                        :disabled="!isEdit"
                    >
                        <el-option
                            label="互联网公开"
                            value="互联网公开"
                        ></el-option>
                        <el-option
                            label="行业内部公开"
                            value="行业内部公开"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别">
                    <el-select
                        v-model="formdata.saveJson.lb"
                        filterable
                        clearable
                        placeholder="请选择类别"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, i) in lbList"
                            :key="i"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="档案建立时间" prop="dajlsj">
                    <el-date-picker
                        v-model="formdata.saveJson.dajlsj"
                        :disabled="!isEdit"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择档案建立时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="文物保护单位名称" prop="wwbhdw">
                    <el-select
                        v-model="formdata.saveJson.wwbhdw"
                        filterable
                        clearable
                        placeholder="请选择文物保护单位名称"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, i) in wbdwList"
                            :key="i"
                            :label="item.DWMC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="资料名称" prop="zlmc">
                    <el-input
                        v-model="formdata.saveJson.zlmc"
                        onkeydown="if(event.keyCode==32) return false"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    v-if="formdata.saveJson.sjmj === '公开'"
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="document"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../0_com_js/edit-button.js";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "assembly_resolutions",
    components: {},
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            rules: {
                sjmj: [
                    {
                        required: true,
                        message: "请选择数据密级",
                        trigger: "blur",
                    },
                ],
                fbfw: [
                    {
                        required: true,
                        message: "请选择发布范围",
                        trigger: "blur",
                    },
                ],
                dajlsj: [
                    {
                        required: true,
                        message: "请选择档案建立时间",
                        trigger: "blur",
                    },
                ],
                wwbhdw: [
                    {
                        required: true,
                        message: "请选择文物保护单位名称",
                        trigger: "change",
                    },
                ],
                zlmc: [
                    {
                        required: true,
                        message: "请填写资料名称",
                        trigger: "blur",
                    },
                ],
            },
            lbList: [
                { id: "0", name: "主卷-文字" },
                { id: "1", name: "主卷-图纸" },
                { id: "2", name: "主卷-照片" },
                { id: "3", name: "主卷-拓片及摹本" },
                { id: "4", name: "主卷-保护规划及保护工程方案" },
                { id: "5", name: "主卷-文物调查及考古发掘资料" },
                { id: "6", name: "主卷-文物保护工程及防治监测" },
                { id: "7", name: "主卷-文物展示" },
                { id: "8", name: "主卷-电子文件" },
                { id: "9", name: "主卷-续补" },
                { id: "10", name: "副卷-行政管理文件" },
                { id: "11", name: "副卷-法律文书" },
                { id: "12", name: "副卷-大事记" },
                { id: "13", name: "副卷-续补" },
            ],
            wbdwList: [],
            formdata: {
                saveJson: {
                    sjmj: "公开",
                    fbfw: "行业内部公开",
                    dajlsj: "",
                    wwbhdw: "",
                    lb: "",
                    zlmc: "",
                    shzt: 1,
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "9",
                userName: "",
                userId: "",
            },
        };
    },
    computed: {},
    mounted() {
        this.getWBDWList();
    },
    methods: {},
};
</script>
<style lang="scss" scoped></style>
